angular.module 'app'
.controller 'AddDefaultCtrl', ($scope, $http, $state, $uibModalInstance, $stateParams, config, Storage, dialogs, growl, data, FileUploader, $rootScope, SweetAlert) ->

  $scope.ui =
    isLoading: false
    appmsg: {
      id: ''
      first: ''
      second: ''
      question: ''
      answer: ''
    }

  findDatas = ->
    if data.appmsg._id
      $scope.ui.appmsg.answer = data.appmsg.answer

  $scope.save = ->
    return growl.error '回覆內容不能為空' if $scope.ui.appmsg.answer == ''
    $http.post "#{config.api.baseUrl}/dsi/qa/set-default?access_token=" + Storage.get('access_token'),
      answer: $scope.ui.appmsg.answer
    .then (res) ->
      growl.success '修改成功'
      $uibModalInstance.close 'Save'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  findDatas()

