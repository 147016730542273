angular.module 'app'
.controller 'ResetPasswordCtrl', ($scope, $http, $timeout, $state, config, Storage, dialogs, growl, SocketIO, SweetAlert, $location, $stateParams) ->

  $scope.ui =
    eadminDisplay: config.eadmin.display
    token: if $location.$$search.token then $location.$$search.token else ''
    newPassword1: ''
    newPassword2: ''
    pwdErr: null

  $scope.thisYear = moment().format('YYYY')

  $scope.doRetrieve = ->
    return growl.error "密碼不能為空" if $scope.ui.newPassword1 == '' || $scope.ui.newPassword2 == ''
    return growl.error "兩次輸入的密碼不一致" if $scope.ui.newPassword1 != $scope.ui.newPassword2
    $http.post "#{config.eadmin.newBaseUrl}/oauth/reset_password",
      password: $scope.ui.newPassword2
      token: $scope.ui.token
    .success (res) ->
      return growl.success "密码修改成功"
    .error (err) ->
      if err.error.indexOf('token invalid') > -1
        $scope.ui.tokenErr = true
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.backHome = ->
    $state.go('home-new')
