angular.module 'app'
.controller 'MenuMatchingCtrl', ($rootScope, $stateParams, $scope, $http, $timeout, $state, config, Storage, growl, dialogs, SweetAlert, findRules) ->

  $scope.ui =
    menuArr: []
    activeMenuArr: {}  # 焦点菜单信息
    isAddBtn: true  # 是否显示主菜单添加按钮
    isSubAddBtn: true  # 是否显示子菜单添加按钮
    isActive: -1  # 主菜单焦点
    isSubActive: -1  # 子菜单焦点
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    individualization: []  #个性化菜单列表
    isDisableSave: false
    sortshow: true
    cancel: false
    activeIndividualization:  # 当前个性化菜单信息
      # menu_name: ''  # 个性化菜单名称
      matchrule:  # 个性化菜单条件
        tag: ''
        country: ''
        province: ''
        city: ''
        language: ''
        sex: ''
        client_platform_type: ''
    menu_name: ''
    wechat: ''
    menu_id: ''


  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  # 获取单个个性化菜单
  do findData = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/personality-menu/#{ $stateParams._id}?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.ui.menu_id = res.data._id
      $scope.ui.menuArr = res.data.button
      $scope.ui.activeIndividualization = res.data.matchrule
      $scope.ui.menu_name = res.data.menu_name
      $scope.ui.isAddBtn = false if $scope.ui.menuArr.length >= 3
    .then ->
      findRules()
    .then (res) ->
      $scope.ui.matchruleList = res
      $scope.ui.activeIndividualization.country = $scope.ui.activeIndividualization.country
      $scope.ui.activeIndividualization.province = $scope.ui.activeIndividualization.province
      $scope.ui.activeIndividualization.city = $scope.ui.activeIndividualization.city
      _.each $scope.ui.matchruleList[0].Val, (data1) ->
        $scope.ui.activeIndividualization.tag = data1.key if $scope.ui.activeIndividualization.tag == data1.value
      _.each $scope.ui.matchruleList[2].Val, (data2) ->
        $scope.ui.activeIndividualization.language = data2.key if $scope.ui.activeIndividualization.language == data2.value
      _.each $scope.ui.matchruleList[3].Val, (data3) ->
        $scope.ui.activeIndividualization.sex = data3.key if $scope.ui.activeIndividualization.sex == data3.value
      _.each $scope.ui.matchruleList[4].Val, (data4) ->
        $scope.ui.activeIndividualization.client_platform_type = data4.key if $scope.ui.activeIndividualization.client_platform_type == data4.value
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 添加主菜单
  $scope.addMenu = ->
    $scope.ui.menuArr.push({'type': 'click', 'name': '', 'key': '', 'action': {'reply_type': 'news', 'articles': []}, 'sub_button': []})
    $scope.ui.isAddBtn = false if $scope.ui.menuArr.length >= 3
    $scope.ui.isActive = $scope.ui.menuArr.length - 1
    $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive]


  # 添加子菜单
  $scope.addSubMenu = ->
    if $scope.ui.menuArr[$scope.ui.isActive].sub_button.length == 0
      delete $scope.ui.menuArr[$scope.ui.isActive].type
      delete $scope.ui.menuArr[$scope.ui.isActive].key
      delete $scope.ui.menuArr[$scope.ui.isActive].action
      delete $scope.ui.menuArr[$scope.ui.isActive].url
      $scope.ui.menuArr[$scope.ui.isActive].sub_button = []
      $scope.ui.menuArr[$scope.ui.isActive].sub_button.push({'type': 'click', 'name': '', 'key': '', 'action': {'reply_type': 'news', 'articles': []}, 'sub_button': []})
      $scope.ui.isSubActive = $scope.ui.menuArr[$scope.ui.isActive].sub_button.length - 1
      $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive].sub_button[$scope.ui.isSubActive]
    else
      $scope.ui.menuArr[$scope.ui.isActive].sub_button.push({'type': 'click', 'name': '', 'key': '', 'action': {'reply_type': 'news', 'articles': []}, 'sub_button': []})
      $scope.ui.isSubActive = $scope.ui.menuArr[$scope.ui.isActive].sub_button.length - 1
      $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive].sub_button[$scope.ui.isSubActive]
    $scope.ui.isSubAddBtn = false if $scope.ui.menuArr[$scope.ui.isActive].sub_button.length >= 5



  # 切换主菜单焦点
  $scope.switchActive = (_index) ->
    $scope.ui.isSubActive = -1
    $scope.ui.isActive = _index
    $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive]
    if $scope.ui.menuArr[$scope.ui.isActive].sub_button.length >= 5
      $scope.ui.isSubAddBtn = false
    else
      $scope.ui.isSubAddBtn = true

  # 切换子菜单焦点
  $scope.switchSubActive = (_index) ->
    $scope.ui.isSubActive = _index
    $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive].sub_button[$scope.ui.isSubActive]


  # 切换菜单内容(菜单内容、跳转网页)
  $scope.switchMenuTypeAction = ->
    if $scope.ui.activeMenuArr.type == 'click'
      delete $scope.ui.activeMenuArr.url
      delete $scope.ui.activeMenuArr.appid
      delete $scope.ui.activeMenuArr.pagepath
      $scope.ui.activeMenuArr.action = {reply_type: "news", articles: []}
    else if $scope.ui.activeMenuArr.type == 'view'
      delete $scope.ui.activeMenuArr.key
      delete $scope.ui.activeMenuArr.action
      delete $scope.ui.activeMenuArr.appid
      delete $scope.ui.activeMenuArr.pagepath
      $scope.ui.activeMenuArr.url = ''
    else if $scope.ui.activeMenuArr.type == 'miniprogram'
      delete $scope.ui.activeMenuArr.key
      delete $scope.ui.activeMenuArr.action
      $scope.ui.activeMenuArr.url = ''

  # 切换发送消息内容(图文、图片、文字)
  $scope.switchSendAction = (_action) ->
    $scope.ui.activeMenuArr.action.reply_type = _action
    if _action == 'news'
      delete $scope.ui.activeMenuArr.action.text
      delete $scope.ui.activeMenuArr.action.path
      delete $scope.ui.activeMenuArr.action.material_id
      $scope.ui.activeMenuArr.action.reply_type = 'news'
      $scope.ui.activeMenuArr.action.articles = []
      $scope.ui.activeMenuArr.action.material_id = ''
    else if _action == 'image'
      delete $scope.ui.activeMenuArr.action.articles
      delete $scope.ui.activeMenuArr.action.text
      $scope.ui.activeMenuArr.action.reply_type = 'image'
      $scope.ui.activeMenuArr.action.path = ''
      $scope.ui.activeMenuArr.action.material_id = ''
    else if _action == 'text'
      delete $scope.ui.activeMenuArr.action.articles
      delete $scope.ui.activeMenuArr.action.path
      delete $scope.ui.activeMenuArr.action.material_id
      $scope.ui.activeMenuArr.action.reply_type = 'text'
      $scope.ui.activeMenuArr.action.text = ''

  # 选择图文
  $scope.selectGraphic = ->
    dialogs.create('apps/material/graphic/modal/modal', 'MaterialModalCtrl', {radio: true;}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.activeMenuArr.action.articles = option[0].articles
      $scope.ui.activeMenuArr.action.material_id = option[0]._id
    ), ->

  # 删除图文
  $scope.delGraphic = ->
    $scope.ui.activeMenuArr.action.articles = []
    $scope.ui.activeMenuArr.action.material_id = ''


  # 选择图片
  $scope.selectImage = ->
    dialogs.create('apps/material/image/choose/choose', 'ImageChooseCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.activeMenuArr.action.path = option[0].file.path
      $scope.ui.activeMenuArr.action.material_id = option[0]._id
    ), ->

  # 删除图片
  $scope.delImage = ->
    $scope.ui.activeMenuArr.action.path = ''
    $scope.ui.activeMenuArr.action.material_id = ''

  # 删除菜单
  $scope.delete = ->
    if $scope.ui.isActive != -1 && $scope.ui.isSubActive == -1
      $scope.ui.menuArr.splice $scope.ui.isActive, 1
      $scope.ui.isActive = -1
      $scope.ui.isSubActive = -1
      $scope.ui.activeMenuArr = {}
      $scope.ui.isAddBtn = true
    else if $scope.ui.isSubActive != -1
      $scope.ui.menuArr[$scope.ui.isActive].sub_button.splice $scope.ui.isSubActive, 1
      $scope.ui.isSubActive = -1
      $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive]
      $scope.ui.isSubAddBtn = true
      if $scope.ui.activeMenuArr.sub_button.length == 0
        delete $scope.ui.activeMenuArr.sub_button
        $scope.ui.activeMenuArr.type = 'click'
        $scope.ui.activeMenuArr.key = ''
        $scope.ui.activeMenuArr.action = {'reply_type': 'news', 'articles': []}
        $scope.ui.activeMenuArr.sub_button = []



  # 菜單保存並發佈
  $scope.save = ->
    $scope.ui.isDisableSave = true
    if $scope.ui.viewActive == 1
      if $scope.ui.menuArr.length == 0
        $scope.ui.isDisableSave = false
        return growl.error "菜單不可為空"
      if _urlStatus == false
        $scope.ui.isDisableSave = false
        return growl.error "鏈接不可為空"

    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/personality-menu/#{$stateParams._id}?access_token=" + Storage.get('access_token'),
      button: $scope.ui.menuArr
      matchrule: $scope.ui.activeIndividualization
      type: "create_menu"
      menu_name: $scope.ui.menu_name
    .then (res) ->
      $scope.ui.isDisableSave = false
      growl.success "保存成功"
      $state.go('app.menumanagement.personalized-menu')
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isDisableSave = false


  # 編輯
  $scope.edit = (matchrule, menu_name) ->
    dialogs.create('apps/features/add-personalized/add-personalized', 'AddPersonalizedCtrl', {matchrule: matchrule, menu_name: menu_name}, {size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      findData()
      $scope.ui.isAddBtn = true
      ), ->

  ## 引用菜單
  $scope.referenceMenu = (menuArr, wechatName) ->
    dialogs.create('apps/features/reference-menu/reference-menu', 'ReferenceMenuCtrl', {menuArr: menuArr,wechatName: wechatName, menu_id: $scope.ui.menu_id}, {size: 'lg'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.menuArr = option.menuArr
      if $scope.ui.menuArr.length < 3
        $scope.ui.isAddBtn = true
      else
        $scope.ui.isAddBtn = false

      # if $scope.ui.menuArr[$scope.ui.isActive].sub_button.length < 5
      #   $scope.ui.isSubAddBtn = true
      # else
      #   $scope.ui.isSubAddBtn = false
     ), ->

  # 開啟排序功能
  $scope.buttonSorting = ->
    $scope.ui.sortshow = false
    $scope.ui.cancel = true

  # 保存排序
  $scope.toSave = ->
    $scope.ui.sortshow = true
    $scope.ui.cancel = false

  # 排序主菜單（含子菜單拖拽移動）
  $scope.sortableOptions =
    update: (e, ui) ->
      $timeout ->
          i = 0
          while i < $scope.ui.menuArr.length
            i++
          return
      return
    stop: (e, ui) ->
      return

  # 排序子菜單（拖拽移動）
  $scope.sortableOptionsSubbutton =
    update: (e, ui) ->
      $timeout ->
        j = 0
        while j < $scope.ui.menuArr[$scope.ui.isActive].sub_button.length
          j++
        return
      return
    stop: (e, ui) ->
      return


  # 監聽字數母
  $scope.bindNumber = ->
    w_num = 0
    _.each $scope.ui.activeMenuArr.name, (w) ->
      if (new RegExp(/^[a-zA-Z]+$/)).test(w)
        w_num += 1
      else
        w_num += 2
    if w_num > 10
      num = 0
      name = ''
      _.each $scope.ui.activeMenuArr.name, (v) ->
        if num < 10
          if (new RegExp(/^[a-zA-Z]+$/)).test(v)
            num += 1
          else
            num += 2
          name += v
      $scope.ui.activeMenuArr.name = name

  # 監聽字數子
  $scope.bindNumberSub = ->
    w_num = 0
    _.each $scope.ui.activeMenuArr.name, (w) ->
      if (new RegExp(/^[a-zA-Z]+$/)).test(w)
        w_num += 1
      else
        w_num += 2
    if w_num > 24
      num = 0
      name = ''
      _.each $scope.ui.activeMenuArr.name, (v) ->
        if num < 24
          if (new RegExp(/^[a-zA-Z]+$/)).test(v)
            num += 1
          else
            num += 2
          name += v
      $scope.ui.activeMenuArr.name = name

  $scope.back = ->
    $state.go 'app.menumanagement.personalized-menu'

  $scope.backPageTemplate = ->
    $scope.$emit "backPageTemplate"
    $state.go('app.pagetemplate')
