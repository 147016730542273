angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.account-overview',
      url: '/account-overview'
      templateUrl: 'apps/account-overview/account-overview'
      controller: 'AccountOverviewCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
