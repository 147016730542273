angular.module 'app', [
  'ui.bootstrap'
  'ui.router'
  'ngAnimate'
  'angular-growl'
  'ng.ueditor'
  'angularFileUpload'
  'dialogs.main'
  'oitozero.ngSweetAlert'
  'daterangepicker'
  'localytics.directives'
  'oc.lazyLoad'
  'sun.scrollable'
  'wu.masonry'
  'btford.socket-io'
  'ui.select2'
  'ngDragDrop'
  'monospaced.qrcode'
  'ui.sortable'
  'ngJcrop'
]

.run ($rootScope, $timeout) ->
  $rootScope.$on '$stateChangeStart', ->
    angular.element('div#loading-bar-spinner').removeAttr('style')

  $rootScope.$on '$stateChangeSuccess', ->
    $timeout (->
      angular.element('div#loading-bar-spinner').css('display','none')
    ), 500

.config ($stateProvider, $urlRouterProvider, $httpProvider) ->
  if !$httpProvider.defaults.headers.get
    $httpProvider.defaults.headers.get = {}

  $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest'

  $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache'
  $httpProvider.defaults.headers.get['Pragma'] = 'no-cache'

  $urlRouterProvider.otherwise("//wechat")

  $stateProvider
    # 项目首页
    .state 'home',
      url: '/home'
      templateUrl: 'apps/home/home'
      controller: 'HomeCtrl',
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'socket/socket.io.js'
              ]
          ])
    # 简版首页
    .state 'home-new',
      url: '/index'
      templateUrl: 'apps/home-new/home-new'
      controller: 'HomeNewCtrl',
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'socket/socket.io.js'
              ]
          ])
    # 簡版重置密碼
    .state 'reset-password',
      url: '/index/reset'
      templateUrl: 'apps/reset-password/reset'
      controller: 'ResetPasswordCtrl',
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'socket/socket.io.js'
              ]
          ])

    .state 'app',
      url: '/:original_id'
      abstract: true
      templateUrl: 'apps/main/main'
      controller: 'MainCtrl',
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'socket/socket.io.js'
              ]
          ])

        activeProject: ($state, $http, config, Storage) ->
          _baseUrl = ''
          if config.eadmin.display == true
            _baseUrl = config.eadmin.newBaseUrl
          else
            _baseUrl = config.eadmin.baseUrl
          $http.get "#{_baseUrl}/account/me",
            params:
              access_token: Storage.get('access_token')
          .then (res) ->
            Storage.set('login_user', res.data)
            Storage.set('user_permission', res.data.permission)
            $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
              params:
                user_group_id: Storage.get('login_user').group_id
                limit: 100
                offset: 0
            .then (res) ->
              Storage.set('Wechats', res.data.data)
              Storage.set('defalult_wechat', res.data.default)
            .catch (error) ->
              $state.go('home-new')

          .catch (error) ->
            $state.go 'home-new'


.config (ngJcropConfigProvider) ->
  ngJcropConfigProvider.setJcropConfig({
    bgColor: 'white'
    bgOpacity: .4
    aspectRatio: 2.35 / 1
    })

  ngJcropConfigProvider.setJcropConfig('listConfig', {
    bgColor: 'white'
    bgOpacity: .4
    aspectRatio: 1
    })

  ngJcropConfigProvider.setJcropConfig('anotherConfig', {
    bgColor: 'white'
    bgOpacity: .2
    aspectRatio: 4 / 3
    })

  ngJcropConfigProvider.setPreviewStyle({
    'width': '160px'
    'height': '68px'
    'overflow': 'hidden'
    'left': '325px'
    'position': 'absolute'
    })

  ngJcropConfigProvider.setPreviewStyle('listConfig',{
    'width': '160px'
    'height': '160px'
    'overflow': 'hidden'
    'left': '325px'
    'position': 'absolute'
    })

  return
