angular.module 'app'
.controller 'AccountOverviewCtrl', ($scope, $rootScope, $stateParams, $http, $state, $timeout, config, Storage, dialogs, SweetAlert, growl, CreateDateText, FileUploader,  $sce) ->
  # return $state.go 'app.home' if Storage.get('user_permission').system.wechat.is_use == false
  $scope.ui =
    user: Storage.get('login_user')
    isLoading: false
    currentPage: 1
    wechat_id: if Storage.get('defalult_wechat_id') == null then Storage.get('Wechats')[0]._id else Storage.get('defalult_wechat_id')
    wechat: ''
    wechat_quota: ''
    perPage: 10
    maxSize: 8
    totalItems: 0
    dataArr: []
    activeStatus: ''
    statistical: ''

  do findDatas = ->
    if $stateParams.original_id
      $scope.ui.isLoading = true
      $http.get "#{config.api.baseUrl}/template2/statistical?access_token=" + Storage.get('access_token'),
        params:
          original_id: $stateParams.original_id
      .then (res) ->
        res.data.all_no = parseInt(res.data.trace_no / res.data.fans_no)
        $scope.ui.statistical = res.data
        $scope.ui.isLoading = true
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')


  findRecords = ->
    if $stateParams.original_id
      $scope.ui.isLoading = true
      $http.get "#{config.api.baseUrl}/template2/record?access_token=" + Storage.get('access_token'),
        params:
          original_id: $stateParams.original_id
          limit: 10
      .then (res) ->
        _.each res.data.data, (data) ->
          data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
          data.real_send_at = moment(data.real_send_at).format('YYYY-MM-DD HH:mm:ss') if data.real_send_at
          data.modeText = '即時發佈' if data.mode == 'real-time'
          data.modeText = '定時發佈' if data.mode == 'timing'
          data.statuText = '等待審核' if data.status == 'created'
          data.statuText = '發送中' if data.status == 'sending'
          data.statuText = '審核駁回' if data.status == 'reject'
          data.statuText = '發送成功' if data.status == 'success'
          data.statuText = '發送失敗' if data.status == 'failure'
          # data.statuText = '發送失敗 ' + data.error_number + ' 人' if data.status == 'no_issue'
          # data.statuText = '發送中' if data.all_done == false && data.status == 'issue'
          data.tip = ''
          html_str = ''
          if data.template.content != undefined
            html_str = data.template.content.replace(new RegExp('\n','g'),'<br>')

            _.each data.data, (obj, name) ->
              obj.color = '#173177' if obj.color == ''
              html_str = html_str.replace(new RegExp('{{' + name + '.DATA}}','g'), '<span style="color:' + obj.color + ';">' + obj.value + '</span>')

          data.tip = $sce.trustAsHtml '<h4 style="color:#555;">' + data.title + '</h4><div class="margin-bottom-md">' + data.created_at + '</div><div style="font-size:13px;">' + html_str + '</div>'

        $scope.ui.dataArr = res.data.data
        $scope.ui.totalItems = res.data.count
        $scope.ui.isLoading = false
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  do findWechat = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/wechat/#{$scope.ui.wechat_id}?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.ui.wechat = res.data
      $scope.ui.wechat_quota = 10 - res.data.quota
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.search = ->
    $scope.ui.totalItems = 0
    $scope.ui.dataArr = []
    $scope.ui.currentPage = 1
    findRecords()
  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.totalItems = 0
      $scope.ui.dataArr = []
      $scope.ui.currentPage = 1
      findRecords()

  $scope.add = ->
    $state.go("app.template-new.template-msg", {})

  $scope.more = ->
    $state.go("app.template-new.template-record", {})


  $scope.reset = ->
    SweetAlert.swal {
      title: "確定重置API調用次數?"
      text: "本月已重置#{$scope.ui.wechat.quota}次（10次/月）。\n\n今天發送通知剩餘額度#{$scope.ui.statistical.trace_no}次\n預計可全體發送#{$scope.ui.statistical.all_no}次"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.post "#{config.api.baseUrl}/wechat/#{$scope.ui.wechat._id}/clear-quota?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findDatas()
          findWechat()
          growl.success "重置成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', findRecords
