angular.module 'app'
.controller 'PreviewModalCtrl', ($scope, $http, $uibModalInstance, $stateParams, Storage, config, growl, data) ->
  $scope.ui =
    user: Storage.get('login_user')

    qrcode_url: ''
    type: data.type
    data: angular.copy data.data

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    createQrcode()
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  createQrcode = ->
    _url = ''
    if data.type == 'article'
      _url = "article/#{$scope.ui.data.programa_id}/#{$scope.ui.data._id}"
    else if data.type == 'column'
      if $scope.ui.data.type is '1'
        _url = "article_list/#{$scope.ui.data._id}"

      else if  $scope.ui.data.type is '2'
        _url = "single_article/#{$scope.ui.data._id}"

      else if  $scope.ui.data.type is '3'
        _url = "images/#{$scope.ui.data._id}"

      else if  $scope.ui.data.type is '4'
        _url = "external_link/#{$scope.ui.data._id}"

    redirect_url = encodeURIComponent "#{config.campus.webapp.baseUrl}?wechat_id=#{$scope.ui.wechat.weixin_id}&group_id=#{$scope.ui.user.group_id}#/#{_url}"
    redirect_uri = encodeURIComponent "#{config.api.baseUrl}/#{$stateParams.original_id}/redirect?redirect_url=#{redirect_url}"
    webappUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=#{$scope.ui.wechat.appid}&redirect_uri=#{redirect_uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect"
    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/log2short", {logUrl: webappUrl}
    .then (res) ->
      $scope.ui.qrcode_url = res.data.shortUrl
    .catch (error) ->
