angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.wechat-setting',
      url: '/wechat-setting'
      templateUrl: 'apps/wechat-setting/layout/layout'
      controller: 'WechatSettingLayoutCtrl'

    .state 'app.wechat-setting.detail',
      url: '/detail'
      templateUrl: 'apps/wechat-setting/wechat-setting'
      controller: 'WechatSettingCtrl'
