angular.module 'app'
.controller 'NewTemplateMsgCtrl', ($scope, $http, $stateParams, $state, config, Storage, $sce,  dialogs, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').issue.template.templateMsg.is_use == false

  $scope.ui =
    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl

    msgObjType: '0'
    isShowTimeSelect: false

    step: 0
    stepStatus1: false
    stepStatus2: false
    stepStatus3: false
    stepStatus4: false
    watach1: false
    watach2: false
    watach3: false
    watach4: false


    tag_id: 'all'
    fansGroups: []
    fansArr: []

    activeObj: {}

    jumpUrl: ''

    date: moment().format('YYYY-MM-DD HH:mm:ss')
    opts:
      autoApply: true
      parentEl: 'div#view-fans-message'
      minDate: moment().format('YYYY-MM-DD HH:mm:ss')
      opens: 'center'
      drops: 'down'
      timePicker: true
      timePicker24Hour: true
      timePickerSeconds: true
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD HH:mm:ss'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  # 處理模板數據
  getTemplate = ->
    if $scope.ui.activeObj.content != undefined
      html_str = $scope.ui.activeObj.content.replace(new RegExp('\n','g'),'<br>').split('<br>')
      keys = []
      _.each html_str, (val) ->
        _name = _key = _val = ''
        _color = '#173177'
        _name = val.split('{{')[0]
        _key = val.split('{{')[1].split('.DATA}}')[0] if val.split('{{')[1] != undefined
        keys.push {name: _name, key: _key, val: _val, color: _color}
      $scope.ui.activeObj.example_html = $sce.trustAsHtml $scope.ui.activeObj.example.replace(new RegExp('\n','g'),'<br>')
      $scope.ui.activeObj.content_val = keys

  # 套用
  $scope.setValue = ->
    $scope.ui.stepStatus2 = true
    $scope.ui.jumpUrl = 'http://mp.weixin.qq.com/s/rGKwEeDV2Ipe6DIOD7-WUA' if $scope.ui.activeObj._id == '58b630664ad46a00187d2f5e'
    _strs = $scope.ui.activeObj.example.replace(new RegExp('\n','g'),'<br>').split('<br>')

    _.each $scope.ui.activeObj.content_val, (data, i) ->
      if data.name == ''
        data.val = _strs[i]
      else
        data.val = _strs[i].substring(_strs[i].indexOf(data.name) + data.name.length)

  # 编制
  $scope.setExample = ->
    $scope.ui.activeObj.content_edit = angular.copy $scope.ui.activeObj.content_val

    _strs = $scope.ui.activeObj.example.replace(new RegExp('\n','g'),'<br>').split('<br>')

    _.each $scope.ui.activeObj.content_edit, (data, i) ->
      data.color = '#173177'
      if data.name == ''
        data.val = _strs[i]
      else
        data.val = _strs[i].substring(_strs[i].indexOf(data.name) + data.name.length)

  # 保存示例
  $scope.saveExample = ->
    _str = angular.copy $scope.ui.activeObj.content
    _.each $scope.ui.activeObj.content_edit, (data, i) ->
      _str = _str.replace('{{' + data.key + '.DATA}}', data.val)

    $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/template/#{$scope.ui.activeObj._id}?access_token=" + Storage.get('access_token'),
      example: _str
    .then (res) ->
      $scope.ui.activeObj.example = _str
      getTemplate()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查詢粉絲分組
  findGroups = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      $scope.ui.fansGroups = res.data.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
  # 打开选择粉丝列表
  findFans = ->
    dlg = dialogs.create('apps/material/graphic/select/select', 'SelectPeopleCtrl', {text: '選擇人數必須小於等於 10000 人，大於等於 1 人', openid: $scope.ui.fansArr}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.fansArr = option
    ), ->

  $scope.open = ->
    dialogs.create('apps/template/template-msg/modal/modal', 'TemplateMsgModalCtrl', {}, {size: 'lg'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.jumpUrl = ''
      $scope.ui.activeObj = option
      $scope.ui.stepStatus1 = true
      getTemplate()
      getWatch()
    ), ->

  $scope.testSend = ->
    verificationTemplate((status, data) ->
      return growl.error data if status == false
      dlg = dialogs.create('apps/material/graphic/select/select', 'SelectPeopleCtrl', {text: '選擇人數必須小於等於 10000 人，大於等於 1 人'}, {size: 'md'})
      dlg.result.then ((option) ->
        return if option == 'Cancel'
        $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/testsend?access_token=" + Storage.get('access_token'),
          template_id: $scope.ui.activeObj._id
          openids: option
          data: data
          url: $scope.ui.jumpUrl
        .then (res) ->
          growl.success('測試發送成功')
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
      ), ->
    )

  # 模板驗證
  verificationTemplate = (callback) ->
    _status = false
    _.each $scope.ui.activeObj, (data) ->
      _status = true
    return callback _status, '請選擇模板' if _status == false

    _str = '{'
    _.each $scope.ui.activeObj.content_val, (key) ->
      key.val = "" if !key.val
      key.val = key.val.replace('\n','').replace('\r','').replace(/\"/g, '\'')
      _str += key.key + ': {value: "' + key.val + '", color: "' + key.color + '"},' if key.key != ''

    _str += '}'
    str = eval("(" + _str + ")")

    _status = true
    _.each str, (e) ->
      _status = false if e.value == ''

    return callback _status, '輸入框不可為空' if _status == false
    return callback _status, str if _status == true

  # 條件數據處理
  conditionData = (callback) ->
    _obj = {}
    _obj.original_id = $stateParams.original_id
    _obj.user_group_id = $scope.ui.user.group_id
    _obj.template_id = $scope.ui.activeObj.template_id
    _obj.mode = 'real-time'
    _obj.mode = 'timing' if $scope.ui.isShowTimeSelect == true
    _obj.expected_send_at = ''
    _obj.expected_send_at = $scope.ui.date if $scope.ui.isShowTimeSelect == true
    _obj.send_to = {}
    _obj.send_to.tagId = 'all' if $scope.ui.msgObjType == '0' && $scope.ui.tag_id == 'all'
    _obj.send_to.tagId = $scope.ui.tag_id if $scope.ui.msgObjType == '0' && $scope.ui.tag_id != 'all'
    _obj.send_to.openids = $scope.ui.fansArr if $scope.ui.msgObjType == '1'
    _obj.created_by = $scope.ui.user.id
    callback _obj

  $scope.submit = ->
    if $scope.ui.date is null or String(new Date($scope.ui.date)) is 'Invalid Date'
      $scope.ui.date = moment().format('YYYY-MM-DD HH:mm:ss')
      return growl.error('請輸入正確的時間')

    verificationTemplate((status, data) ->
      return growl.error data if status == false
      conditionData((obj) ->
        return growl.error '自定義粉絲人數必須小於等於 10000 人，大於等於 1 人' if $scope.ui.msgObjType == '1' && (obj.send_to.openids.length < 1 || obj.send_to.openids.length > 10000)
        obj.data = data
        obj.url = $scope.ui.jumpUrl
        $scope.ui.stepStatus3 = true
        $http.post "#{config.api.baseUrl}/template2/record?access_token=" + Storage.get('access_token'),  obj
        .then (res) ->
          growl.success('發佈申請提交成功')
          $scope.ui.stepStatus4 = true
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
      )
    )

  $scope.$watch 'ui.msgObjType', (newValue) ->
    if newValue == '0'
      findGroups()

  $scope.chooseFans = ->
    findFans()

  $scope.setColor = (_index, _color) ->
    $scope.ui.activeObj.content_val[_index].color = _color


  getWatch = ->
    $scope.$watch 'ui.activeObj.content_val[0].val', (newValue) ->
      if newValue != ''
        $scope.ui.watach1 = true
      else
        $scope.ui.watach1 = false

    $scope.$watch 'ui.activeObj.content_val[1].val', (newValue) ->
      if newValue != ''
        $scope.ui.watach2 = true
      else
        $scope.ui.watach2 = false

    $scope.$watch 'ui.activeObj.content_val[2].val', (newValue) ->
      if newValue != ''
        $scope.ui.watach3 = true
      else
        $scope.ui.watach3 = false

    $scope.$watch 'ui.activeObj.content_val[3].val', (newValue) ->
      if newValue != ''
        $scope.ui.watach4 = true
      else
        $scope.ui.watach4 = false

  $scope.$watch 'ui.watach1 + ui.watach2 + ui.watach3 + ui.watach4', (newValue) ->
    if newValue == 4
      $scope.ui.stepStatus2 = true
    else
      $scope.ui.stepStatus2 = false