angular.module 'app'
.controller 'FansTestCtrl', ($rootScope, $timeout, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').manage.fans.is_use == false

  $scope.ui =
    isUploadData: false

    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 8
    maxSize: 8
    totalItems: 0
    isSelectAll: false
    fans: []
    openidList: []
    sortby: "-subscribe_time"
    ungroups: [
        name: '全部'
        tag_id: 'all'
        tag_count: 0
    ]
    activeGroup:
      name: '全部'
      tag_id: 'all'
    groups: []
    search:
      nickname: null
    selectGroups: []
    delDatas: []

    sex: ''

    country: ''
    province: ''
    city: ''

    language: ''

    compare: ''
    dayNumber: ''

    remark: ''

    start_subscribe_time: ''
    start_subscribe_time_show: ''
    end_subscribe_time: ''
    end_subscribe_time_show: ''

    tagsPopoverDisabled: false
    newGroupDisabled: false
    editGroupDisabled: false
    editFansRemarkDisabled: false
    fanRemarkPopoverDisabled: false

  # 查詢粉絲和分組
  findDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
      _params =
        nickname: $scope.ui.search.nickname
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        sort: $scope.ui.sortby

      _params.sex = $scope.ui.sex if $scope.ui.sex != ''

      _params.country = $scope.ui.country if $scope.ui.country != ''
      _params.province = $scope.ui.province if $scope.ui.province != ''
      _params.city = $scope.ui.city if $scope.ui.city != ''

      _params.language = $scope.ui.language if $scope.ui.language != ''

      if $scope.ui.start_subscribe_time != ''
        _params.start_subscribe_time = $scope.ui.start_subscribe_time
        $scope.ui.start_subscribe_time_show = moment($scope.ui.start_subscribe_time).format('YYYY-MM-DD')

      if $scope.ui.end_subscribe_time != ''
        _params.end_subscribe_time = $scope.ui.end_subscribe_time
        $scope.ui.end_subscribe_time_show = moment($scope.ui.end_subscribe_time).format('YYYY-MM-DD')

      _params.compare = $scope.ui.compare if $scope.ui.compare != ''
      _params.dayNumber = $scope.ui.dayNumber if $scope.ui.dayNumber != ''

      _params.remark = $scope.ui.remark if $scope.ui.remark != ''

      $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/infors/#{$scope.ui.activeGroup.tag_id}?access_token=" + Storage.get('access_token'),
        params: _params
      .then (res) ->
        $scope.ui.isUploadData = res.data.can_update

        $scope.ui.ungroups[0].tag_count = res.data.count if $scope.ui.ungroups[0].tag_count < 1

        $scope.ui.totalItems = res.data.count
        $scope.ui.fans = res.data.data
        _.each $scope.ui.fans, (fan) ->
          fan.group_name = $scope.ui.activeGroup.name
          fan.subscribe_time = moment(fan.subscribe_time).format('YYYY-MM-DD')

          fan.tags = []
          _.each fan.tagid_list, (id) ->
            _.each $scope.ui.groups, (group) ->
              if String(id) is group.tag_id
                fan.tags.push group.name

          isSelect = false
          _.each $scope.ui.openidList, (openid) ->
            isSelect = true if fan.openid == openid
          fan.isChecked = isSelect
      .finally ->
        $scope.ui.isLoading = false
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 排序
  $scope.changeSort = (type) ->
    $scope.ui.sortby = type
    findDatas()

  $scope.search = ->
    $scope.ui.sex = ''
    $scope.ui.country = ''
    $scope.ui.province = ''
    $scope.ui.city = ''
    $scope.ui.language = ''
    $scope.ui.compare = ''
    $scope.ui.dayNumber = ''
    $scope.ui.remark = ''
    $scope.ui.start_subscribe_time = ''
    $scope.ui.start_subscribe_time_show = ''
    $scope.ui.end_subscribe_time = ''
    $scope.ui.end_subscribe_time_show = ''

    $scope.ui.totalItems = 0
    $scope.ui.fans = []
    $scope.ui.currentPage = 1
    findDatas()

  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.sex = ''
      $scope.ui.country = ''
      $scope.ui.province = ''
      $scope.ui.city = ''
      $scope.ui.language = ''
      $scope.ui.compare = ''
      $scope.ui.dayNumber = ''
      $scope.ui.remark = ''
      $scope.ui.start_subscribe_time = ''
      $scope.ui.start_subscribe_time_show = ''
      $scope.ui.end_subscribe_time = ''
      $scope.ui.end_subscribe_time_show = ''

      $scope.ui.totalItems = 0
      $scope.ui.fans = []
      $scope.ui.currentPage = 1
      findDatas()


  $scope.selectAll = ->
    if $scope.ui.isSelectAll == true
      _.each $scope.ui.fans, (fan) ->
        if fan.isChecked == false
          fan.isChecked = true
          $scope.ui.openidList.push fan.openid
    else
      _.each $scope.ui.fans, (fan) ->
        if fan.isChecked == true
          fan.isChecked = false
          $scope.ui.openidList = _.filter $scope.ui.openidList, (openid) ->
            openid if fan.openid isnt openid

  $scope.selectSingle = (data) ->
    if data.isChecked == true
      $scope.ui.openidList.push data.openid
    else
      $scope.ui.openidList = _.filter $scope.ui.openidList, (openid) ->
        openid if data.openid isnt openid

  # 選中左邊標簽
  $scope.setActiveGroup = (group) ->
    $scope.ui.search.nickname = null

    $scope.ui.sex = ''
    $scope.ui.country = ''
    $scope.ui.province = ''
    $scope.ui.city = ''
    $scope.ui.language = ''
    $scope.ui.compare = ''
    $scope.ui.dayNumber = ''
    $scope.ui.remark = ''
    $scope.ui.start_subscribe_time = ''
    $scope.ui.start_subscribe_time_show = ''
    $scope.ui.end_subscribe_time = ''
    $scope.ui.end_subscribe_time_show = ''

    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.fans = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.updateFans = ->
    dialogs.wait '請稍後', '正在更新粉絲信息，請勿做其他操作...'
    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/update?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      $rootScope.$broadcast 'dialogs.wait.complete'
      if res.status == 200
        findDatas()
        growl.success "更新粉絲信息成功"
      else
        growl.error "網絡連接失敗，請稍後重試"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $rootScope.$broadcast 'dialogs.wait.complete'

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.progress = 0
  $scope.exportFans = ->
    $scope.progress = 0

    _params =
      nickname: $scope.ui.search.nickname

    _params.sex = $scope.ui.sex if $scope.ui.sex != ''

    _params.country = $scope.ui.country if $scope.ui.country != ''
    _params.province = $scope.ui.province if $scope.ui.province != ''
    _params.city = $scope.ui.city if $scope.ui.city != ''

    _params.language = $scope.ui.language if $scope.ui.language != ''

    _params.start_subscribe_time = $scope.ui.start_subscribe_time if $scope.ui.start_subscribe_time != ''
    _params.end_subscribe_time = $scope.ui.end_subscribe_time if $scope.ui.end_subscribe_time != ''

    _params.compare = $scope.ui.compare if $scope.ui.compare != ''
    _params.dayNumber = $scope.ui.dayNumber if $scope.ui.dayNumber != ''

    _params.remark = $scope.ui.remark if $scope.ui.remark != ''

    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/export/#{$scope.ui.activeGroup.tag_id}?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data: _params
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  # 刪除標籤
  $scope.delGroup = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{$scope.ui.activeGroup.name}”標籤？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        dialogs.wait '請稍後', '正在處理...'
        $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag/#{$scope.ui.activeGroup.tag_id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->

          $scope.ui.activeGroup = $scope.ui.groups[0]
          $scope.ui.totalItems = 0
          $scope.ui.fans = []
          $scope.ui.currentPage = 1
          findDatas()

          growl.success "刪除成功！"
          $rootScope.$broadcast 'dialogs.wait.complete'
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
          $rootScope.$broadcast 'dialogs.wait.complete'

  # 批量移除
  $scope.delData = ->
    return growl.error "請選擇粉絲" if $scope.ui.openidList.length < 1
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定移除所選粉絲？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        dialogs.wait '請稍後', '正在處理...'

        tagid_list = []
        _.each $scope.ui.groups, (group) ->
          if group.tag_id == $scope.ui.activeGroup.tag_id
            tagid_list.push parseInt(group.tag_id)

        $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/batchun-tagging?access_token=" + Storage.get('access_token'),
          openid_list: $scope.ui.openidList
          tagid_list: tagid_list
        .then (res) ->


          growl.success "移除成功！"
          $rootScope.$broadcast 'dialogs.wait.complete'
          findDatas()
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  # 新增分組
  $scope.groupPopover =
    name: ''
    isOpen: false
    title: "請填寫標籤名稱"
    templateUrl: 'groupPopoverTemplate.html'
    open: ->
      $scope.groupPopover.isOpen = true
      $scope.ui.newGroupDisabled = false
    close: ->
      $scope.groupPopover.isOpen = false
      $scope.ui.newGroupDisabled = false
    save: ->
      dialogs.wait '請稍後', '正在處理...'
      $scope.ui.newGroupDisabled = true
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'),
        tag:
          name: $scope.groupPopover.name
      .then (res) ->
        findDatas()
        growl.success "保存成功！"
        $scope.groupPopover.isOpen = false
        $scope.groupPopover.name = ''
        $scope.ui.newGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.newGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'

  # 修改組名
  $scope.editGroupPopover =
    isOpen: false
    title: "修改標籤"
    name: ''
    templateUrl: 'editGroupPopoverTemplate.html'
    open: ->
      $scope.editGroupPopover.isOpen = true
      $scope.ui.editGroupDisabled = false
    close: ->
      $scope.editGroupPopover.isOpen = false
      $scope.ui.editGroupDisabled = false
    save: ->
      dialogs.wait '請稍後', '正在處理...'
      $scope.ui.editGroupDisabled = true
      $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag/#{$scope.ui.activeGroup.tag_id}?access_token=" + Storage.get('access_token'),
        name: $scope.editGroupPopover.name
      .then (res) ->
        $scope.ui.activeGroup.name = $scope.editGroupPopover.name
        _.each $scope.ui.groups, (group) ->
          if group.tag_id is $scope.ui.activeGroup.tag_id
            group.name = $scope.editGroupPopover.name
        growl.success "保存成功！"
        $scope.editGroupPopover.isOpen = false
        $scope.ui.editGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.editGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'

  # 打標籤及修改備註
  $scope.fanRemarkPopover =
    title: "標籤及備註"
    data: null
    remark: ''
    templateUrl: 'fanRemarkPopoverTemplate.html'
    isOpen: false
    save: ->
      tagid_list = []
      _.each $scope.ui.groups, (group) ->
        if group.isChecked is true
          tagid_list.push parseInt(group.tag_id)
      $scope.ui.editGroupDisabled = true
      dialogs.wait '請稍後', '正在處理...'
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/members_tag?access_token=" + Storage.get('access_token'),
        openid_list: $scope.ui.openidList
        tagid_list: tagid_list
      .then (res) ->
        findDatas()
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.editGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'

      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/remark?access_token=" + Storage.get('access_token'),
        openid: $scope.fanRemarkPopover.data.openid
        remark: $scope.fanRemarkPopover.remark
      .then (res) ->
        _.each $scope.ui.fans, (fan) ->
          if $scope.fanRemarkPopover.data._id is fan._id
            fan.remark = $scope.fanRemarkPopover.remark
        $scope.ui.editGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'
        growl.success "保存成功！"
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.editGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'

  $scope.setFanRemarkPopover = (fan) ->
    $scope.fanRemarkPopover.data = angular.copy fan
    if $scope.fanRemarkPopover.data.remark.length > 0
      $scope.fanRemarkPopover.remark = $scope.fanRemarkPopover.data.remark
    else
      $scope.fanRemarkPopover.remark = $scope.fanRemarkPopover.data.nickname
    $scope.ui.openidList = (->
      _.toArray arguments
    ) fan.openid

    _.each $scope.ui.groups, (group) ->
      isSelect = false
      _.each fan.tagid_list, (tagId) ->
        if group.tag_id == String(tagId)
          isSelect = true
      group.isChecked = isSelect

  $scope.selectGroup = (data) ->
    tagid_list = []
    _.each $scope.ui.groups, (group) ->
      if group.isChecked is true
        tagid_list.push parseInt(group.tag_id)
    if tagid_list.length > 3
      data.isChecked = false
      return growl.error '最多可選三個标签'

  # 移動分組
  $scope.exchangePopover =
    data: null
    title: "打標籤"
    templateUrl: 'exchangePopoverTemplate.html'
    save: ->
      tagid_list = []
      _.each $scope.exchangePopover.groups, (group) ->
        if group.isChecked is true
          tagid_list.push parseInt(group.tag_id)
      return growl.error "請選擇標籤" if tagid_list.length < 1
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/members_tag?access_token=" + Storage.get('access_token'),
        openid_list: [$scope.exchangePopover.data.openid]
        tagid_list: tagid_list
      .then (res) ->
        growl.success "打標籤成功！"
        findDatas()
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  $scope.setExchangePopover = (fan) ->
    $scope.exchangePopover.data = angular.copy fan
    $scope.exchangePopover.groups = angular.copy $scope.ui.groups
    _.each fan.tagid_list, (id) ->
      _.each $scope.exchangePopover.groups, (group) ->
        if String(id) is group.tag_id
          group.isChecked = true

  # 打標籤
  $scope.tagsPopover =
    title: "打標籤"
    templateUrl: 'tagsPopoverTemplate.html'
    isOpen: false
    open: ->
      $scope.tagsPopover.isOpen = true
      $scope.ui.tagsPopoverDisabled = false
    close: ->
      $scope.tagsPopover.isOpen = false
      $scope.ui.tagsPopoverDisabled = false
    save: ->
      tagid_list = []
      _.each $scope.tagsPopover.groups, (group) ->
        if group.isChecked is true
          tagid_list.push parseInt(group.tag_id)

      return growl.error "請選擇粉絲" if $scope.ui.openidList.length < 1

      return growl.error "最多可選三個标签" if tagid_list.length > 3

      $scope.ui.tagsPopoverDisabled = true

      dialogs.wait '請稍後', '正在處理...'
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/members_tag?access_token=" + Storage.get('access_token'),
        openid_list: $scope.ui.openidList
        tagid_list: tagid_list
      .then (res) ->
        $rootScope.$broadcast 'dialogs.wait.complete'
        $scope.tagsPopover.isOpen = false
        growl.success "打標籤成功！"
        findDatas()
      .catch (error) ->
        $rootScope.$broadcast 'dialogs.wait.complete'
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.tagsPopoverDisabled = false

  $scope.setTagsPopover = ->
    $scope.ui.tagsPopoverDisabled = false
    $scope.tagsPopover.groups = angular.copy $scope.ui.groups
    _.each $scope.tagsPopover.groups, (group) ->
      group.isChecked = false

  # 加入黑名單
  $scope.blacklistPopover =
    title: "加入黑名單"
    templateUrl: 'blacklistPopoverTemplate.html'
    isOpen: false
    open: ->
      $scope.blacklistPopover.isOpen = true
    close: ->
      $scope.blacklistPopover.isOpen = false
    save: ->
      return growl.error "請選擇粉絲" if $scope.ui.openidList.length < 1

      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/black_list?access_token=" + Storage.get('access_token'),
        openid_list: $scope.ui.openidList
        to_groupid: ['1']
      .then (res) ->
        $scope.blacklistPopover.isOpen = false
        growl.success "加入黑名單成功！"
        findDatas()
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 移出黑名單
  $scope.removeBlacklistPopover =
    title: "移出黑名單"
    templateUrl: 'removeBlacklistPopoverTemplate.html'
    isOpen: false
    open: ->
      $scope.removeBlacklistPopover.isOpen = true
    close: ->
      $scope.removeBlacklistPopover.isOpen = false
    save: ->
      return growl.error "請選擇粉絲" if $scope.ui.openidList.length < 1

      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/black_list?access_token=" + Storage.get('access_token'),
        openid_list: $scope.ui.openidList
        to_groupid: ['0']
      .then (res) ->
        $scope.removeBlacklistPopover.isOpen = false
        growl.success "移出黑名單成功！"
        findDatas()
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 高級搜索
  $scope.advancedSearch = ->
    dlg = dialogs.create('apps/fans/fans-modal/fans-modal', 'FansModalCtrl', {name: $scope.ui.activeGroup.name}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.search.nickname = null

      $scope.ui.sex = option.sex
      $scope.ui.country = option.country
      $scope.ui.province = option.province
      $scope.ui.city = option.city
      $scope.ui.language = option.language
      $scope.ui.compare = option.compare
      $scope.ui.dayNumber = option.dayNumber
      $scope.ui.remark = option.remark
      $scope.ui.start_subscribe_time = option.start_subscribe_time
      $scope.ui.end_subscribe_time = option.end_subscribe_time

      findDatas()
    ), ->

  findDatas()
  $scope.$watch 'ui.currentPage', findDatas
