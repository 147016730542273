angular.module 'app'
.controller 'DefaultQaCtrl', ($rootScope, $scope, $http, $stateParams, $state, $location, config, Storage, SweetAlert, $timeout, dialogs, growl, FileUploader) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').interaction.smartService.is_use == false

  $scope.ui =
    isLoading: false
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    appmsgs: []
    rebuild: ''
    last_rebuild_time: ''
    choseTesters: []

  findDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/dsi/qa?access_token=" + Storage.get('access_token'),
      params:
        question: ''
        answer: ''
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        is_default: true
    .then (res) ->
      _.each res.data.data, (data) ->
        data.updated_at = moment(data.updated_at).format('YYYY-MM-DD')
      $scope.ui.rebuild = res.data.rebuild
      $scope.ui.appmsgs = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.last_rebuild_time = moment(res.data.last_rebuild_time.prod).format('YYYY-MM-DD HH:mm') if res.data.last_rebuild_time.prod
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findTesters = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/dsi/qa-tester?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.choseTesters = res.data.is_add
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  #修改
  $scope.editDetail = (appmsg) ->
    dlg = dialogs.create('apps/smartService/add/addDefault', 'AddDefaultCtrl', {appmsg: appmsg, type: 'default'}, {size: 'lg'})
    dlg.result.then ((option) ->
      findDatas()
      return if option == 'Cancel'
    ), ->

  # 重跑模型
  $scope.rebuildModel = ->
    return if $scope.ui.rebuild.prod == false && $scope.ui.rebuild.test == false
    dlg = dialogs.create('apps/smartService/rebuild/rebuildModel', 'RebuildModelCtrl', {qa: 'qa', last_rebuild_time: $scope.ui.last_rebuild_time, rebuild: $scope.ui.rebuild, choseTesters: $scope.ui.choseTesters}, {size: 'lg'})
    dlg.result.then ((option) ->
      findDatas()
      findTesters()
      return if option == 'Cancel'
    ), ->

  findDatas()
