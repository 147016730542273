angular.module 'app'
.controller 'InformationMessageCtrl', ($scope, $http, $uibModalInstance, $stateParams, $sce, $state, config, Storage, CreateHtml, growl, data) ->
  $scope.ui =
    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl
    msg: ''
    content: data.content
    childent: data.childent
    type: data.type
    show: data.show

  $scope.ui.content.content.articles[0].content_html = CreateHtml.toHtml $scope.ui.content.content.articles[0].content if $scope.ui.content.type == 'news'

  $scope.ui.content.content.file.url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + $scope.ui.content.content.file.path) if $scope.ui.content.type == 'video'

  $scope.ui.childent.content_html = CreateHtml.toHtml $scope.ui.childent.content if $scope.ui.type == '1'
  _.each $scope.ui.content.histroy, (data) ->
    data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')

  $scope.submit = ->
    return growl.error('留言不可為空') if $scope.ui.msg == ''
    $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/send/#{$scope.ui.content._id}?access_token=" + Storage.get('access_token'),
      histroy:
        content: $scope.ui.msg
        created_by: $scope.ui.user.id
    .then (res) ->
      growl.success('留言成功')
      $uibModalInstance.close 'Save'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
