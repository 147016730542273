angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.template-new',
      url: '/template-new'
      templateUrl: 'apps/template-new/layout/layout'
      controller: 'NewTemplateLayout2Ctrl'

    .state 'app.template-new.template-msg',
      url: '/template-msg'
      templateUrl: 'apps/template-new/template-msg/template-msg'
      controller: 'NewTemplateMsgCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'Highcharts-4.2.4/js/highcharts.src.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
              ]
          ])

    .state 'app.template-new.template-examine',
      url: '/template-examine'
      templateUrl: 'apps/template-new/template-examine/template-examine'
      controller: 'NewTemplateExamineCtrl'

    .state 'app.template-new.template-record',
      url: '/template-record'
      templateUrl: 'apps/template-new/template-record/template-record'
      controller: 'NewTemplateRecordCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
