angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.template',
      url: '/template-manage'
      templateUrl: 'apps/template/layout/layout'
      controller: 'TemplateLayoutCtrl'

    .state 'app.template.template-library',
      url: '/template-library'
      templateUrl: 'apps/template/template-library/template-library'
      controller: 'TemplateLibraryCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
              ]
          ])

    .state 'app.template.template-uesr',
      url: '/template-uesr'
      templateUrl: 'apps/template/template-uesr/template-uesr'
      controller: 'TemplateUesrCtrl'

    .state 'app.template2',
      url: '/template2'
      templateUrl: 'apps/template/layout2/layout2'
      controller: 'TemplateLayout2Ctrl'

    .state 'app.template2.template-msg',
      url: '/template-msg'
      templateUrl: 'apps/template/template-msg/template-msg'
      controller: 'TemplateMsgCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'Highcharts-4.2.4/js/highcharts.src.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
              ]
          ])

    .state 'app.template2.template-examine',
      url: '/template-examine'
      templateUrl: 'apps/template/template-examine/template-examine'
      controller: 'TemplateExamineCtrl'

    .state 'app.template2.template-record',
      url: '/template-record'
      templateUrl: 'apps/template/template-record/template-record'
      controller: 'TemplateRecordCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
