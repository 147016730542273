angular.module 'app'

.config (growlProvider) ->
  growlProvider.onlyUniqueMessages false

.controller 'MsgManageCtrl', ($rootScope, $scope, $http, $timeout, $state, $stateParams, config, $sce, Storage, growl, CreateHtml, CreateDateText, dialogs) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').interaction.message.is_use == false

  $scope.switchKey = 1

  $scope.ui =
    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl

    search:
      nickname: ''  # 粉丝名称


    isListLoading: false  # 列表頁加載動畫顯示狀態
    isContentLoading: false  # 內容頁加載動畫顯示狀態
    isCollectLoading: false  # 收藏頁加載動畫顯示狀態

    listShowMore: false  # 列表頁加載更多按鈕顯示狀態
    contentShowMore: false   # 內容頁加載更多按鈕顯示狀態
    collectShowMore: false   # 收藏頁加載更多按鈕顯示狀態

    listDisableMore: true  # 列表頁加載更多按鈕禁用狀態
    contentDisableMore: true   # 內容頁加載更多按鈕禁用狀態
    collectDisableMore: true   # 收藏頁加載更多按鈕禁用狀態

    isShowListKey: false   # 是否隱藏列表頁關鍵字回復，true: 隱藏，false: 顯示
    # isShowContentKey: false   # 是否隱藏內容頁關鍵字回復

    listNum: 10  # 列表頁每頁顯示條數
    contentNum: 8  # 內容頁每頁顯示條數
    collectNum: 8  # 收藏頁每頁顯示條數

    listDataArr: []  # 獲取列表頁數據包
    contentDataArr: []  # 獲取內容頁數據包(單用戶)
    collectDataArr: []  # 獲取已收藏消息

    sortState: 1  # 時間排序，默認1(留言时间降序)

    activeIndex: -1  # 當前所選列表項
    activeContentIndex: -1  # 當前所選內容項(回復消息所需)

    messageTimeOut: true  # 消息內容是否超時(最新一條)

    menuActive: 0  # 發送消息菜單選項焦點切換
    sendMessage: ''  # 文字消息內容

    date:  # 時間控件
      startDate: moment().subtract(29, 'days')
      endDate: moment()
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#view-fans-message'
  $scope.ui.opts.opens = 'left'

  # 獲取公眾號信息
  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  $scope.switch = (key) ->
    return if $scope.switchKey == key
    $scope.switchKey = key

    $scope.getListData($scope.ui.search.nickname) if $scope.switchKey == 1
    if $scope.switchKey == 2
      $scope.ui.collectShowMore = false
      $scope.ui.collectDisableMore = true
      $scope.ui.collectDataArr = []
      $scope.getCollectData()

  # 獲取內容
  $scope.getContentData = (_id, _startDate, _endDate) ->
    if _startDate is null or _endDate is null or String(new Date(_startDate)) is 'Invalid Date' or String(new Date(_endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(29, 'days')
        endDate: moment()
      _startDate = $scope.ui.date.startDate
      _endDate = $scope.ui.date.endDate
      growl.error('請輸入正確的時間')

    $scope.ui.isContentLoading = true

    _params =
      limit: $scope.ui.contentNum
      offset: $scope.ui.contentDataArr.length
      # show: $scope.ui.isShowContentKey
      show: $scope.ui.isShowListKey
      sort: '-created_at'

    _params.start_created_at = moment(_startDate).format('YYYY-MM-DD 00:00:00') if _startDate != undefined
    _params.end_created_at = moment(_endDate).format('YYYY-MM-DD 23:59:59') if _endDate != undefined

    _params.sort = 'created_at' if $scope.ui.sortState == 2
    _params.sort = '-reply_time' if $scope.ui.sortState == 3
    _params.sort = 'reply_time' if $scope.ui.sortState == 4

    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/message/#{_id}?access_token=" + Storage.get('access_token'),
      params: _params

    .then (res) ->
      # 格式化數據包中文字及圖片
      _.each res.data.data, (contentData) ->
        contentData.action.msg_time_at = moment(contentData.action.msg_time).format('MM月DD日')
        contentData.action.msg_time = CreateDateText.format contentData.action.msg_time

        if contentData.sender is "follower"
          if contentData.action.msg_type is "text"
            contentData.action.msg_content.text = CreateHtml.toHtml contentData.action.msg_content.text
          else if contentData.action.msg_type is "image"
            # contentData.action.msg_content.image_url = $scope.ui.resourcesUrl + contentData.action.msg_content.path
            contentData.action.msg_content.image_url = "#{config.api.baseUrl}/imgserve?_url=" + encodeURIComponent(contentData.action.msg_content.picUrl)
          else if contentData.action.msg_type is "shortvideo"
            contentData.action.msg_content.video_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentData.action.msg_content.path)
          else if contentData.action.msg_type is "voice"
            contentData.action.msg_content.audio_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentData.action.msg_content.path)

          if contentData.action.reply_state != '0'
            _.each contentData.reply, (contentVal) ->
              contentVal.reply_time = CreateDateText.format contentVal.reply_time

              if contentVal.reply_type is "text"
                contentVal.reply_content.content = CreateHtml.toHtml contentVal.reply_content.content
              else if contentVal.reply_type is "image"
                contentVal.reply_content.image_url = $scope.ui.resourcesUrl + contentVal.reply_content.path
              else if contentVal.reply_type is "video"
                contentVal.reply_content.video_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentVal.reply_content.path)
              else if contentVal.reply_type is "voice"
                contentVal.reply_content.audio_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentVal.reply_content.path)

        else if contentData.sender is "weixin"
          if contentData.action.msg_type is "text"
            contentData.action.msg_content.text = CreateHtml.toHtml contentData.action.msg_content.content
          else if contentData.action.msg_type is "image"
            contentData.action.msg_content.image_url = $scope.ui.resourcesUrl + contentData.action.msg_content.path
          else if contentData.action.msg_type is "video"
            contentData.action.msg_content.video_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentData.action.msg_content.path)
          else if contentData.action.msg_type is "voice"
            contentData.action.msg_content.audio_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentData.action.msg_content.path)
          else if contentData.action.msg_type is "template"
            contentData.action.template.content_html = $sce.trustAsHtml(contentData.action.template.content)

      $scope.ui.contentDataArr = $scope.ui.contentDataArr.concat(res.data.data)

      # 判斷數據長度改變‘加載更多’按鈕狀態
      if res.data.count > $scope.ui.contentNum
        $scope.ui.contentDisableMore = false if $scope.ui.contentDataArr.length == res.data.count
        $scope.ui.contentShowMore = true
      else
        $scope.ui.contentShowMore = false

      getSingleList()
    .finally ->
      $scope.ui.isContentLoading = false

    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 獲取列表
  $scope.getListData = (_nickname) ->
    $scope.ui.isListLoading = true

    _params =
      limit: $scope.ui.listNum
      offset: $scope.ui.listDataArr.length
      show: $scope.ui.isShowListKey

    _params.nickname = _nickname if _nickname != undefined && _nickname != ''

    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/message?access_token=" + Storage.get('access_token'),
      params: _params

    .then (res) ->
      # 格式化數據包中文字及圖片
      _.each res.data.data, (listData) ->
        listData.action.msg_time = CreateDateText.format listData.action.msg_time
        if listData.action.msg_type is "text"
          listData.action.msg_content.text = CreateHtml.toHtml listData.action.msg_content.text
        else if listData.action.msg_type is "image"
          listData.action.msg_content.picUrl = "#{config.api.baseUrl}/imgserve?_url=" + encodeURIComponent(listData.action.msg_content.picUrl)
        else if listData.action.msg_type is "shortvideo"
          listData.action.msg_content.video_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + listData.action.msg_content.path)
        else if listData.action.msg_type is "voice"
          listData.action.msg_content.audio_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + listData.action.msg_content.path)

      $scope.ui.listDataArr = $scope.ui.listDataArr.concat(res.data.data)

      # 獲取默認內容頁數據
      if $scope.ui.listDataArr.length > 0
        _index = 0

        if $stateParams.id != ''
          _.each $scope.ui.listDataArr, (data, i) ->
            _index = i if $stateParams.id == data._id

        $scope.selectContentData(_index)
      else
        $scope.ui.contentShowMore = false
        $scope.ui.contentDisableMore = true
        $scope.ui.contentDataArr = []
        $scope.ui.activeIndex = -1
        $scope.ui.activeContentIndex = -1
        $scope.ui.menuActive = 0
        $scope.ui.sendMessage = ''
        $scope.ui.messageTimeOut = true

      # 判斷數據長度改變‘加載更多’按鈕狀態
      if res.data.count > $scope.ui.listNum
        $scope.ui.listDisableMore = false if res.data.count == $scope.ui.listDataArr.length
        $scope.ui.listShowMore = true
      else
        $scope.ui.listShowMore = false

    .finally ->
      $scope.ui.isListLoading = false

    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 獲取已收藏消息
  $scope.getCollectData = ->
    $scope.ui.isCollectLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/message/star/all?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.collectNum
        offset: $scope.ui.collectDataArr.length
    .then (res) ->
      # 格式化數據包中文字及圖片
      _.each res.data.data, (contentData) ->
        contentData.action.msg_time = CreateDateText.format contentData.action.msg_time

        if contentData.action.msg_type is "text"
          contentData.action.msg_content.text = CreateHtml.toHtml contentData.action.msg_content.text
        else if contentData.action.msg_type is "image"
          contentData.action.msg_content.image_url = "#{config.api.baseUrl}/imgserve?_url=" + encodeURIComponent(contentData.action.msg_content.picUrl)
        else if contentData.action.msg_type is "shortvideo"
          contentData.action.msg_content.video_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentData.action.msg_content.path)
        else if contentData.action.msg_type is "voice"
          contentData.action.msg_content.audio_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentData.action.msg_content.path)


        if contentData.action.reply_state != '0'
          _.each contentData.reply, (contentVal) ->
            contentVal.reply_time = CreateDateText.format contentVal.reply_time

            if contentVal.reply_type is "text"
              contentVal.reply_content.content = CreateHtml.toHtml contentVal.reply_content.content
            else if contentVal.reply_type is "image"
              contentVal.reply_content.image_url = $scope.ui.resourcesUrl + contentVal.reply_content.path
            else if contentVal.reply_type is "video"
              contentVal.reply_content.video_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentVal.reply_content.path)
            else if contentVal.reply_type is "voice"
              contentVal.reply_content.audio_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentVal.reply_content.path)


      $scope.ui.collectDataArr = $scope.ui.collectDataArr.concat(res.data.data)

      # 判斷數據長度改變‘加載更多’按鈕狀態
      if res.data.count > $scope.ui.collectNum
        $scope.ui.collectDisableMore = false if $scope.ui.collectDataArr.length == res.data.count
        $scope.ui.collectShowMore = true
      else
        $scope.ui.collectShowMore = false

    .finally ->
      $scope.ui.isCollectLoading = false

    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查看詳細內容
  $scope.selectContentData = (_index) ->
    $scope.ui.contentShowMore = false
    $scope.ui.contentDisableMore = true
    $scope.ui.contentDataArr = []
    $scope.ui.activeIndex = _index

    $scope.ui.activeContentIndex = -1

    $scope.ui.messageTimeOut = true
    $scope.ui.messageTimeOut = false if $scope.ui.listDataArr[_index].is_timeout == '0'

    $scope.ui.menuActive = 1
    $scope.ui.sendMessage = ''
    $scope.ui.sendMessage = '消息已超時，不可發送消息' if $scope.ui.messageTimeOut == true

    $scope.getContentData($scope.ui.listDataArr[_index]._id, $scope.ui.date.startDate, $scope.ui.date.endDate)

  # 內容頁加載更多
  $scope.moreContentData = ->
    $scope.getContentData($scope.ui.listDataArr[$scope.ui.activeIndex]._id, $scope.ui.date.startDate, $scope.ui.date.endDate)

  # (添加/取消)收藏
  $scope.switchCollection = (_index, _state) ->
    $scope.ui.contentDataArr[_index].is_star = _state
    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/message/star/#{$scope.ui.contentDataArr[_index]._id}?access_token=" + Storage.get('access_token'),
      is_star: _state

    .then (res) ->
      growl.success('添加收藏成功！') if $scope.ui.contentDataArr[_index].is_star == true
      growl.success('取消收藏成功！') if $scope.ui.contentDataArr[_index].is_star == false

    .catch (error) ->
      $scope.ui.contentDataArr[_index].is_star = !_state
      growl.error('添加收藏失敗！') if $scope.ui.contentDataArr[_index].is_star == false
      growl.error('取消收藏失敗！') if $scope.ui.contentDataArr[_index].is_star == true

  # 取消收藏(收藏页)
  $scope.switchCollect = (_data) ->
    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/message/star/#{_data._id}?access_token=" + Storage.get('access_token'),
      is_star: false
    .then (res) ->
      growl.success('取消收藏成功！')
      $scope.ui.collectDataArr = []
      $scope.getCollectData()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 按名称筛选
  $scope.searchName = ->
    $scope.ui.listDataArr = []
    $scope.getListData($scope.ui.search.nickname)

  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
        $scope.ui.totalItems = 0
        $scope.ui.currentPage = 1
        $scope.ui.listDataArr = []
        $scope.getListData($scope.ui.search.nickname)

  # 按時間篩選
  $scope.search = ->
    return if $scope.ui.listDataArr.length < 1
    $scope.ui.contentShowMore = false
    $scope.ui.contentDisableMore = true
    $scope.ui.contentDataArr = []
    $scope.getContentData($scope.ui.listDataArr[$scope.ui.activeIndex]._id, $scope.ui.date.startDate, $scope.ui.date.endDate)

  # 按時間排序
  $scope.switchSortState = (_state) ->
    return if $scope.ui.listDataArr.length < 1
    $scope.ui.sortState = _state
    $scope.ui.contentShowMore = false
    $scope.ui.contentDisableMore = true
    $scope.ui.contentDataArr = []
    $scope.getContentData($scope.ui.listDataArr[$scope.ui.activeIndex]._id, $scope.ui.date.startDate, $scope.ui.date.endDate)

  # 切換回復按鈕狀態
  $scope.switchReplyBtn = (_index) ->
    if $scope.ui.activeContentIndex == _index
      $scope.ui.activeContentIndex = -1
      $scope.switchMenu(0)
    else
      $scope.ui.activeContentIndex = _index
      $scope.switchMenu(1)

  # 切换直接点击菜单按钮状态
  $scope.switchMenuBtn = (_index) ->
    # 再次點擊，取消選中
    if _index == 0 || $scope.ui.menuActive == _index
      $scope.ui.activeContentIndex = -1
      $scope.ui.menuActive = 0
      $scope.ui.sendMessage = ''
      return

    $scope.switchMenu(_index)

  # 獲取最新消息(單條)
  getSingleMessage = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/message/reply/#{$scope.ui.contentDataArr[$scope.ui.activeContentIndex]._id}?access_token=" + Storage.get('access_token'),
      params:
        show: $scope.ui.isShowListKey
    .then (res) ->
      if res.data.data.length != 1
        growl.error('更新消息失敗！')
        return

      _.each res.data.data, (contentData) ->
        contentData.action.msg_time = CreateDateText.format contentData.action.msg_time

        if contentData.action.msg_type is "text"
          contentData.action.msg_content.text = CreateHtml.toHtml contentData.action.msg_content.text
        else if contentData.action.msg_type is "image"
          contentData.action.msg_content.image_url = "#{config.api.baseUrl}/imgserve?_url=" + encodeURIComponent(contentData.action.msg_content.picUrl)
        else if contentData.action.msg_type is "shortvideo"
          contentData.action.msg_content.video_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentData.action.msg_content.path)
        else if contentData.action.msg_type is "voice"
          contentData.action.msg_content.audio_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentData.action.msg_content.path)



        if contentData.action.reply_state != '0'
          _.each contentData.reply, (contentVal) ->
            contentVal.reply_time = CreateDateText.format contentVal.reply_time

            if contentVal.reply_type is "text"
              contentVal.reply_content.content = CreateHtml.toHtml contentVal.reply_content.content
            else if contentVal.reply_type is "image"
              contentVal.reply_content.image_url = $scope.ui.resourcesUrl + contentVal.reply_content.path
            else if contentVal.reply_type is "video"
              contentVal.reply_content.video_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentVal.reply_content.path)
            else if contentVal.reply_type is "voice"
              contentVal.reply_content.audio_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + contentVal.reply_content.path)


      $scope.ui.contentDataArr[$scope.ui.activeContentIndex] = res.data.data[0]
      $scope.ui.activeContentIndex = -1
      $scope.ui.menuActive = 0

    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 獲取最新列表信息(單人)
  getSingleList = ->
    $scope.ui.isListLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/messageOne/#{$scope.ui.listDataArr[$scope.ui.activeIndex]._id}?access_token=" + Storage.get('access_token'),
      params:
        show: $scope.ui.isShowListKey
    .then (res) ->
      res.data.data[0].action.msg_time = CreateDateText.format res.data.data[0].action.msg_time
      if res.data.data[0].action.msg_type is "text"
        res.data.data[0].action.msg_content.text = CreateHtml.toHtml res.data.data[0].action.msg_content.text
      else if res.data.data[0].action.msg_type is "image"
        res.data.data[0].action.msg_content.picUrl = "#{config.api.baseUrl}/imgserve?_url=" + encodeURIComponent(res.data.data[0].action.msg_content.picUrl)
      else if res.data.data[0].action.msg_type is "shortvideo"
        res.data.data[0].action.msg_content.video_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + res.data.data[0].action.msg_content.path)
      else if res.data.data[0].action.msg_type is "voice"
        res.data.data[0].action.msg_content.audio_url = $sce.trustAsResourceUrl($scope.ui.resourcesUrl + res.data.data[0].action.msg_content.path)

      $scope.ui.listDataArr[$scope.ui.activeIndex] = res.data.data[0]
      $scope.ui.isListLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isListLoading = false

  # 發送消息
  sendMessage = (_msgtype, _content, callback) ->
    $scope.ui.isContentLoading = true

    sendType = 'send'
    sendId = $scope.ui.listDataArr[$scope.ui.activeIndex]._id

    sendType = 'reply' if $scope.ui.activeContentIndex != -1
    sendId = $scope.ui.contentDataArr[$scope.ui.activeContentIndex]._id if $scope.ui.activeContentIndex != -1

    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/message/#{sendType}/#{sendId}?access_token=" + Storage.get('access_token'),
      msgtype: _msgtype
      content: _content

    .then (res) ->
      if res.status == 200
        callback(true)
      else
        callback(false)
      $scope.ui.isContentLoading = false

    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      callback(false)
      $scope.ui.isContentLoading = false

  # 發送消息
  $scope.sendClick = (mediaId) ->
    return if $scope.ui.listDataArr.length < 1 || $scope.ui.sendMessage == '' || $scope.ui.menuActive != 1

    sendMessage('text', {content: $scope.ui.sendMessage}, (_state) ->
      if _state == true
        $scope.ui.sendMessage = ''
        if $scope.ui.activeContentIndex != -1
          getSingleMessage()
          getSingleList()
        else
          $scope.selectContentData($scope.ui.activeIndex)
    )

  # 發送消息菜單選項焦點切換...........
  $scope.switchMenu = (_index) ->
    return if $scope.ui.listDataArr.length < 1

    $scope.ui.menuActive = _index

    # 更改可回復狀態
    if $scope.ui.messageTimeOut == true
      $scope.ui.sendMessage = '消息已超時，不可發送消息'
      return
    else
      $scope.ui.sendMessage = ''

    # 調用图文選擇并發送图文消息
    if $scope.ui.menuActive == 2
      dialogs.create('apps/material/graphic/modal/modal', 'MaterialModalCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
      .result.then ((option) ->
        $scope.ui.menuActive = 1
        $scope.ui.sendMessage = ''
        $scope.ui.sendMessage = '消息已超時，不可發送消息' if $scope.ui.messageTimeOut == true
        if option == 'Cancel'
          $scope.ui.activeContentIndex = -1
          return

        sendMessage('mpnews', {material_id: option[0]._id}, (_state) ->
          if _state == true
            if $scope.ui.activeContentIndex != -1
              getSingleMessage()
              getSingleList()
            else
              $scope.selectContentData($scope.ui.activeIndex)
        )
      ), ->

    # 調用圖片選擇并發送圖片消息
    if $scope.ui.menuActive == 3
      dialogs.create('apps/material/image/choose/choose', 'ImageChooseCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
      .result.then ((option) ->
        $scope.ui.menuActive = 1
        $scope.ui.sendMessage = ''
        $scope.ui.sendMessage = '消息已超時，不可發送消息' if $scope.ui.messageTimeOut == true
        if option == 'Cancel'
          $scope.ui.activeContentIndex = -1
          return

        sendMessage('image', {material_id: option[0]._id}, (_state) ->
          if _state == true
            if $scope.ui.activeContentIndex != -1
              getSingleMessage()
              getSingleList()
            else
              $scope.selectContentData($scope.ui.activeIndex)
        )
      ), ->

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  # 下載當前粉絲數據
  $scope.downloadData = ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(29, 'days')
        endDate: moment()
      return growl.error('請輸入正確的時間')

    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/message/export/#{$scope.ui.listDataArr[$scope.ui.activeIndex]._id}?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        start_created_at: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_created_at: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
      prepareCallback: prepare
      successCallback: success
      failCallback: error


  # 下載全部粉絲數據
  $scope.downloadAllData = ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(29, 'days')
        endDate: moment()
      return growl.error('請輸入正確的時間')
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/message/export/all?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        start_created_at: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_created_at: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
      prepareCallback: prepare
      successCallback: success
      failCallback: error


  # 发送通知
  $scope.sendTemplateMsg = (index) ->
    dialogs.create('apps/template/template-msg/modal/modal', 'TemplateMsgModalCtrl', {}, {size: 'lg'})
    .result.then ((option) ->
      return if option == 'Cancel'
      _params =
        user: $scope.ui.listDataArr[index]
        data: option

      dialogs.create('apps/message/msg-modal/msg-modal', 'MsgModalCtrl', _params, {size: 'lg'})
      .result.then ((res) ->
        return if res == 'Cancel'
        $scope.getListData()
      ), ->
    ), ->

  # 初始化
  $scope.getListData()
