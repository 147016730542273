angular.module 'app'
.controller 'HeaderCtrl', ($rootScope, $scope, $state, $http, config, Storage, dialogs, growl, SocketIO, $location) ->
  $scope.ui =
    loginUser: Storage.get('login_user')
    permission: Storage.get('user_permission')
    token: Storage.get('access_token')
    eadminDisplay: config.eadmin.display

  $scope.logout = ->
    SocketIO.emit 'clientID', 'WeChat-Login-' + Storage.get('login_user').id, null

    SocketIO.removeListener 'WeChat-Login-' + Storage.get('login_user').id

    localStorage.email = ""
    localStorage.password = ""
    localStorage.isAutoSignIn = false
    Storage.remove('access_token')
    Storage.remove('login_user')
    Storage.remove('user_permission')
    Storage.remove('Wechats')
    Storage.remove('defalult_wechat')
    Storage.remove('defalult_wechat_id')
    if $location.$$absUrl.indexOf('microdata.ersinfotech.com/') > -1
      $state.go('home')
    else
      $state.go("home-new")

  $scope.editUser = ->
    dlg = dialogs.create('apps/user/user-data/user-data', 'UserDataCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.loginUser.username = option
    ), ->

  $scope.editPassword = ->
    dlg = dialogs.create('apps/user/user-password/user-password', 'UserPasswordCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      SocketIO.emit 'clientID', 'WeChat-Login-' + Storage.get('login_user').id, null

      localStorage.email = ""
      localStorage.password = ""
      localStorage.isAutoSignIn = false
      Storage.remove('access_token')
      Storage.remove('login_user')
      Storage.remove('user_permission')
      Storage.remove('Wechats')
      Storage.remove('defalult_wechat')
      Storage.remove('defalult_wechat_id')
      $state.go('home')
    ), ->

  $rootScope.$on 'headerWechat', (event, option) ->
    _baseUrl = ''
    if $scope.ui.eadminDisplay == true
      _baseUrl = config.eadmin.newBaseUrl
    else
      _baseUrl = config.eadmin.baseUrl

    $http.get "#{_baseUrl}/account/me",
      params:
        access_token: Storage.get('access_token')
    .then (res) ->
      $scope.ui.permission = res.data.permission
    .catch (error) ->
      $state.go('home')
