angular.module 'app'
.controller 'WechatSettingCtrl', ($scope, $rootScope, $stateParams, $http, $state, $timeout, config, Storage, dialogs, SweetAlert, growl, CreateDateText, FileUploader) ->
  # return $state.go 'app.home' if Storage.get('user_permission').system.wechat.is_use == false
  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    isLoading: false
    user: Storage.get('login_user')
    wechat: ''
    wechat_id: if Storage.get('defalult_wechat_id') == null then $scope.ui.wechats[0]._id else Storage.get('defalult_wechat_id')

    isDisableSave: false
  do findDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/wechat/#{$scope.ui.wechat_id}?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.ui.wechat = res.data
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.save = ->
    $http.put "#{config.api.baseUrl}/wechat/#{$scope.ui.wechat_id}?access_token=" + Storage.get('access_token'), $scope.ui.wechat
    .then (res) ->
      growl.success "保存成功！"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isDisableSave = false

  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    url: config.api.baseUrl + "/upload?access_token=" + Storage.get('access_token')
  )
  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 1
        growl.error("圖片文件不能大於1MB!", {referenceId: 1})
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.wechat.headimgurl = response.url_path
    growl.success '上傳成功'
    uploader.clearQueue()

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    growl.error("上傳圖片失敗!", {referenceId: 1})
    uploader.clearQueue()
