angular.module 'app'
.controller 'QrcodeResponseCtrl', ($rootScope, $scope, $http, $state, $sce, $uibModalInstance, $stateParams, config, Storage, dialogs, growl, data) ->

  $scope.ui =
    qrcode: []
    fansGroups: []
    rule:
      man: false
      male: false
      all: false
      cn: false
      tw: false
      en: false
    resourcesUrl: config.api.baseUrl
    example_html: ''
    user: Storage.get('login_user')
    widthh: ''

  # 查詢粉絲分組
  findGroups = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      $scope.ui.fansGroups = res.data.data
      $scope.ui.fansGroups.unshift {name: '--- 無 ---', tag_id: 'notagId'}
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  do initialization = ->
    if $scope.ui.user.group_id == 1
      $scope.ui.widthh = '25%'
    else
      $scope.ui.widthh = '33%'
    $scope.ui.example_html = $sce.trustAsHtml data.qrcode.action.example.replace(new RegExp('\n','g'),'<br>') if data.qrcode.action.reply_type == 'template'
    $scope.ui.qrcode = data.qrcode
    $scope.ui.qrcode.action = {} if !$scope.ui.qrcode.action
    if !$scope.ui.qrcode.is_action
      $scope.ui.rule.man = true
      $scope.ui.rule.male = true
      $scope.ui.rule.all = true
      $scope.ui.rule.cn = true
      $scope.ui.rule.tw = true
      $scope.ui.rule.en = true
      $scope.ui.qrcode.is_action = true
      $scope.ui.qrcode.rule = {}
      $scope.ui.qrcode.rule.start_time = "00:00"
      $scope.ui.qrcode.rule.end_time = "23:59"
      if !$scope.ui.qrcode.rule.sexs or $scope.ui.qrcode.rule.sexs.length > 1
        $scope.ui.qrcode.rule.sexs = ["1", "2"]
      if _.contains $scope.ui.qrcode.rule.languages, "all"
        $scope.ui.qrcode.rule.languages = ["all", "zh_CN", "zh_TW", "en"]
      if !$scope.ui.qrcode.rule.languages or $scope.ui.qrcode.rule.languages.length > 2
        $scope.ui.qrcode.rule.languages = ["all", "zh_CN", "zh_TW", "en"]
    else
      $scope.ui.rule.man = true if _.contains $scope.ui.qrcode.rule.sexs, "1"
      $scope.ui.rule.male = true if _.contains $scope.ui.qrcode.rule.sexs, "2"
      $scope.ui.rule.all = true if _.contains $scope.ui.qrcode.rule.languages, "all"
      $scope.ui.rule.cn = true if _.contains $scope.ui.qrcode.rule.languages, "zh_CN"
      $scope.ui.rule.tw = true if _.contains $scope.ui.qrcode.rule.languages, "zh_TW"
      $scope.ui.rule.en = true if _.contains $scope.ui.qrcode.rule.languages, "en"

    findGroups()

  $scope.switchSendAction = (_action) ->
    $scope.ui.qrcode.action.reply_type = _action
    $scope.ui.qrcode.action = {} if !$scope.ui.qrcode.action

  # 选择图文
  $scope.selectGraphic = ->
    dialogs.create('apps/material/graphic/modal/modal', 'MaterialModalCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.qrcode.action.articles = option[0].articles
      $scope.ui.qrcode.action.material_id = option[0]._id
    ), ->

  # 删除图文
  $scope.delGraphic = ->
    $scope.ui.qrcode.action.articles = []
    $scope.ui.qrcode.action.material_id = ''

  $scope.selectImage = ->
    dialogs.create('apps/material/image/choose/choose', 'ImageChooseCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.qrcode.action = {} if !$scope.ui.qrcode.action
      $scope.ui.qrcode.action.path = option[0].file.path
      $scope.ui.qrcode.action.material_id = option[0]._id
    ), ->

  # 删除图片
  $scope.delImage = ->
    $scope.ui.qrcode.action.path = ''
    $scope.ui.qrcode.action.material_id = ''

  # 選擇模板
  $scope.selectTemplate = ->
    dialogs.create('apps/qrcode/template/template-modal', 'TemplateModalCtrl', {}, {size: 'lg'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.qrcode.action.example = option.example
      $scope.ui.example_html = $sce.trustAsHtml option.example.replace(new RegExp('\n','g'),'<br>')
      $scope.ui.qrcode.action.template_id = option._id
      $scope.ui.qrcode.action.data = option.data
      $scope.ui.qrcode.action.url = option.jumpUrl

    ), ->

  # 删除模板
  $scope.delTemplate = ->
    $scope.ui.qrcode.action.data = {}
    $scope.ui.qrcode.action.template_id = ''


  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    return growl.error "回覆內容不可為空" if !$scope.ui.qrcode.action.material_id && $scope.ui.qrcode.action.reply_type == 'news'
    return growl.error "回覆內容不可為空" if !$scope.ui.qrcode.action.material_id && $scope.ui.qrcode.action.reply_type == 'image'
    return growl.error "回覆內容不可為空" if !$scope.ui.qrcode.action.template_id && $scope.ui.qrcode.action.reply_type == 'template'
    return growl.error "回覆內容不可為空" if !$scope.ui.qrcode.action.text && $scope.ui.qrcode.action.reply_type == 'text'
    return growl.error "名稱不可為空" if $scope.ui.qrcode.name == ''

    if $scope.ui.qrcode.action.reply_type != 'news'
      delete $scope.ui.qrcode.action.articles
    if $scope.ui.qrcode.action.reply_type != 'image'
      delete $scope.ui.qrcode.action.media_id
      delete $scope.ui.qrcode.action.path
    if $scope.ui.qrcode.action.reply_type != 'text'
      delete  $scope.ui.qrcode.action.text
    if $scope.ui.qrcode.action.reply_type != 'template'
      delete $scope.ui.qrcode.action.data
      delete $scope.ui.qrcode.action.example
      delete $scope.ui.qrcode.action.template_id
      delete $scope.ui.qrcode.action.url
      delete $scope.ui.qrcode.action.delTemplate
    if $scope.ui.qrcode.action.reply_type == 'template' || $scope.ui.qrcode.action.reply_type == 'text'
      delete $scope.ui.qrcode.action.material_id

    $scope.ui.qrcode.rule.sexs = []
    $scope.ui.qrcode.rule.languages = []
    $scope.ui.qrcode.rule.sexs.push "1" if $scope.ui.rule.man == true
    $scope.ui.qrcode.rule.sexs.push "2" if $scope.ui.rule.male == true
    if $scope.ui.rule.all == true
      $scope.ui.qrcode.rule.languages.push "all"
    else
      $scope.ui.qrcode.rule.languages.push "zh_CN" if $scope.ui.rule.cn == true
      $scope.ui.qrcode.rule.languages.push "zh_TW" if $scope.ui.rule.tw == true
      $scope.ui.qrcode.rule.languages.push "en" if $scope.ui.rule.en == true


    $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/#{$scope.ui.qrcode._id}?access_token=" + Storage.get('access_token'), $scope.ui.qrcode
    .then (res) ->
      $uibModalInstance.close 'Save'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.back = ->
    $uibModalInstance.close 'Cancel'
    $scope.$emit "backPageTemplate"
    $state.go('app.pagetemplate')