angular.module 'app'
.controller 'MobileCtrl', ($scope, $stateParams, $http, config, Storage, dialogs, growl, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').manage.mobile.is_use == false

  # return growl.info('移動運營功能即將開放，請耐心等候')

  $scope.ui =
    user: Storage.get('login_user')

    data: {}

    isLoading: true

  do getData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/mobile/account?access_token=" + Storage.get('access_token'),
      params:
        user_id: $scope.ui.user.id
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.data = res.data
      if res.data != null
        $scope.ui.data.masssend_fail = false if !$scope.ui.data.masssend_fail
      if $scope.ui.data && $scope.ui.data.is_bind == true && $scope.ui.data.preview_openid && $scope.ui.data.preview_openid.is_bind == false
        SweetAlert.swal {
          title: "提示"
          text: "請確定預覽接收人！"
          type: "warning"
          showCancelButton: false
          confirmButtonClass: 'btn-danger'
          confirmButtonText: '確定'
        }, (isConfirm) ->

      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  # 绑定
  $scope.bindWechat = ->
    dlg = dialogs.create('apps/mobile/mobile-modal/mobile-modal', 'MobileModalCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
      getData()
    ), ->

  # 解除微信号
  $scope.deleteWechat = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定解除綁定當前微信號！"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $scope.ui.isLoading = true
        $http.post "#{config.api.baseUrl}/mobile/account/modifywechat?access_token=" + Storage.get('access_token'),
          user_id: $scope.ui.user.id
        .then (res) ->
          getData()
          growl.success "解除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
          $scope.ui.isLoading = false

  # 更换微信号
  $scope.replacement = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定更換當前微信號！"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $scope.ui.isLoading = true
        $http.post "#{config.api.baseUrl}/mobile/account/modifywechat?access_token=" + Storage.get('access_token'),
          user_id: $scope.ui.user.id
        .then (res) ->
          $scope.ui.isLoading = false
          getData()
          $scope.bindWechat()
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
          $scope.ui.isLoading = false

  # 开启/关闭服务
  $scope.switch = ->
    if !$scope.ui.data.is_bind
      if $scope.ui.data.preview_openid
        $http.post "#{config.api.baseUrl}/mobile/account/relate?access_token=" + Storage.get('access_token'),
          original_id: $stateParams.original_id
          user_id: $scope.ui.user.id
          openid: $scope.ui.data.preview_openid.openid
        .then (res) ->
          getData()
          growl.success "開啟成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
      else
        SweetAlert.swal {
          title: "開啟服務最后一步"
          text: "請選擇預覽接收人"
          type: "warning"
          showCancelButton: true
          confirmButtonClass: 'btn-danger'
          cancelButtonText: '取消'
          confirmButtonText: '確定'
        }, (isConfirm) ->
          if isConfirm
            dlg = dialogs.create('apps/material/graphic/select/select', 'SelectPeopleCtrl', {text: '', single: true}, {size: 'md'})
            dlg.result.then ((option) ->
              return if option == 'Cancel'
              $http.post "#{config.api.baseUrl}/mobile/account/relate?access_token=" + Storage.get('access_token'),
                original_id: $stateParams.original_id
                user_id: $scope.ui.user.id
                openid: option[0]
              .then (res) ->
                getData()
                growl.success "開啟成功！"
              .catch (error) ->
                if error.data.error.indexOf('errtext') >= 0
                  err = JSON.parse error.data.error
                  growl.error err.errtext
                else
                  growl.error('網絡連接失敗，請稍後重試')
            ), ->
    else
      SweetAlert.swal {
        title: "你確定嗎?"
        text: "是否確定關閉服務"
        type: "warning"
        showCancelButton: true
        confirmButtonClass: 'btn-danger'
        cancelButtonText: '取消'
        confirmButtonText: '確定'
      }, (isConfirm) ->
        if isConfirm
          $http.delete "#{config.api.baseUrl}/mobile/account/#{$scope.ui.data._id}?access_token=" + Storage.get('access_token') + "&original_id=" + $stateParams.original_id + "&user_id=" + $scope.ui.user.id, {}
          .then (res) ->
            getData()
            growl.success "關閉成功！"
          .catch (error) ->
            if error.data.error.indexOf('errtext') >= 0
              err = JSON.parse error.data.error
              growl.error err.errtext
            else
              growl.error('網絡連接失敗，請稍後重試')

  # 修改提醒日期
  $scope.editTime = (_bool, type) ->
    if _bool == true
      $scope.ui.editTime = false
      $scope.ui.data.audit_times.start_time = ''
      $scope.ui.data.audit_times.end_time = ''
      getData()
      return

    if type == true
      start_time = new Date(moment().format('YYYY-MM-DD ') + $scope.ui.data.audit_times.start_time + ':00')
      $scope.ui.data.audit_times.start_time = new Date()
      $scope.ui.data.audit_times.start_time.setHours(start_time.getHours())
      $scope.ui.data.audit_times.start_time.setMinutes(start_time.getMinutes())

      end_time = new Date(moment().format('YYYY-MM-DD ') + $scope.ui.data.audit_times.end_time + ':00')
      $scope.ui.data.audit_times.end_time = new Date()
      $scope.ui.data.audit_times.end_time.setHours(end_time.getHours())
      $scope.ui.data.audit_times.end_time.setMinutes(end_time.getMinutes())
      return
    else
      if $scope.ui.data.audit_times.start_time == null || $scope.ui.data.audit_times.end_time == null
        $scope.ui.data.audit_times.start_time = '09:00'
        $scope.ui.data.audit_times.end_time = '18:30'
        return growl.error "請輸入正確的時間格式"

      $scope.ui.data.audit_times.start_time = moment($scope.ui.data.audit_times.start_time).format('HH:mm')
      $scope.ui.data.audit_times.end_time = moment($scope.ui.data.audit_times.end_time).format('HH:mm')

      SweetAlert.swal {
        title: "你確定嗎?"
        text: "是否確定修改提醒時段！"
        type: "warning"
        showCancelButton: true
        confirmButtonClass: 'btn-danger'
        cancelButtonText: '取消'
        confirmButtonText: '確定'
      }, (isConfirm) ->
        if isConfirm
          $scope.ui.isLoading = true
          _params =
            user_id: $scope.ui.user.id
            original_id: $stateParams.original_id
            start_time: $scope.ui.data.audit_times.start_time
            end_time: $scope.ui.data.audit_times.end_time

          $http.put "#{config.api.baseUrl}/mobile/account/#{$scope.ui.data._id}?access_token=" + Storage.get('access_token'), _params
          .then (res) ->
            getData()
            growl.success "修改成功！"
          .catch (error) ->
            if error.data.error.indexOf('errtext') >= 0
              err = JSON.parse error.data.error
              growl.error err.errtext
            else
              growl.error('網絡連接失敗，請稍後重試')
            getData()

  # 修改提醒日期
  $scope.editWeek = (_bool, type) ->
    if _bool == true
      $scope.ui.editWeek = false
      getData()
      return

    return if type == true

    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定修改提醒日期！"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $scope.ui.isLoading = true
        _params =
          user_id: $scope.ui.user.id
          original_id: $stateParams.original_id
          weeks: $scope.ui.data.audit_times.weeks

        $http.put "#{config.api.baseUrl}/mobile/account/#{$scope.ui.data._id}?access_token=" + Storage.get('access_token'), _params
        .then (res) ->
          getData()
          growl.success "修改成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
          getData()

  # 修改权限
  $scope.editState = (_obj, _val) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定修改該權限！"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $scope.ui.isLoading = true
        _params =
          user_id: $scope.ui.user.id
          original_id: $stateParams.original_id

        _params[_obj] = _val

        $http.put "#{config.api.baseUrl}/mobile/account/#{$scope.ui.data._id}?access_token=" + Storage.get('access_token'), _params
        .then (res) ->
          getData()
          growl.success "修改成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
          getData()

  # 修改预览对象
  $scope.editPreviewOpenid = ->
    dlg = dialogs.create('apps/material/graphic/select/select', 'SelectPeopleCtrl', {text: '', single: true}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $http.post "#{config.api.baseUrl}/mobile/account/modifyPreviewOpenid?access_token=" + Storage.get('access_token'),
        user_id: $scope.ui.user.id
        original_id: $stateParams.original_id
        openid: option[0]
      .then (res) ->
        getData()
        growl.success "修改成功！"
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        getData()
    ), ->
