angular.module 'app'
.controller 'QrcodeModalCtrl', ($scope, $http, $stateParams, $state, config, Storage, SweetAlert, dialogs, growl, data, $uibModalInstance, $timeout, $rootScope) ->

  $scope.ui =
    isLoading: false
    data: data
    tagsPopoverDisabled: false
    tagsPopoverDisabled2: false
    fans: []
    menuFans: []
    ungroups: [
      name: '全部'
      tag_id: 'all'
    ]

  # 菜單
  do findMenuDatas = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/menu/follower_list?access_token=" + Storage.get('access_token'),
      params:
        version: $scope.ui.data.version
        key_id: $scope.ui.data.key_id
        start_created_at: $scope.ui.data.startDate
        end_created_at: $scope.ui.data.endDate
    .then (res) ->
      $scope.ui.menuFans = res.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 二維碼
  do findQrcodeDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/follower/list?access_token=" + Storage.get('access_token'),
      params:
        qrcodeId: $scope.ui.data._id
    .then (res) ->
      $scope.ui.fans = res.data
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false


  do findGroundDatas = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.tagsPopover.groups = []
      $scope.tagsPopover.groups = _.union($scope.ui.ungroups, res.data.data)
      $scope.tagsPopover2.groups = []
      $scope.tagsPopover2.groups = _.union($scope.ui.ungroups, res.data.data)
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 菜單點擊人數打標籤
  $scope.tagsPopover =
    title: "打標籤"
    templateUrl: 'tagsPopoverTemplate.html'
    isOpen: false
    open: ->
      $scope.tagsPopover.isOpen = true
      $scope.ui.tagsPopoverDisabled = false
    close: ->
      $scope.tagsPopover.isOpen = false
      $scope.ui.tagsPopoverDisabled = false
    save: ->
      tagid_list = []
      _.each $scope.tagsPopover.groups, (group) ->
        if group.isChecked is true
          tagid_list.push parseInt(group.tag_id)

      return growl.error "請至少選擇一個標籤" if tagid_list.length == 0
      return growl.error "最多可選三個標籤" if tagid_list.length > 3

      $scope.ui.tagsPopoverDisabled = true

      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/menu/tagby_follower_list?access_token=" + Storage.get('access_token'),
        version: $scope.ui.data.version
        key_id: $scope.ui.data.key_id
        start_created_at: $scope.ui.data.startDate
        end_created_at: $scope.ui.data.endDate
        tagid_list: tagid_list
      .then (res) ->
        $scope.tagsPopover.isOpen = false
        growl.success "打標籤成功！"
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.tagsPopoverDisabled = false

  # 二維碼掃碼次數打標籤
  $scope.tagsPopover2 =
    title: "打標籤"
    templateUrl: 'tagsPopoverTemplate2.html'
    isOpen: false
    open: ->
      $scope.tagsPopover2.isOpen = true
      $scope.ui.tagsPopoverDisabled2 = false
    close: ->
      $scope.tagsPopover2.isOpen = false
      $scope.ui.tagsPopoverDisabled2 = false
    save: ->
      tagid_list = []
      _.each $scope.tagsPopover2.groups, (group) ->
        if group.isChecked is true
          tagid_list.push parseInt(group.tag_id)

      return growl.error "請至少選擇一個標籤" if tagid_list.length == 0
      return growl.error "最多可選三個標籤" if tagid_list.length > 3

      $scope.ui.tagsPopoverDisabled2 = true
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/follower/tagby_list?access_token=" + Storage.get('access_token'),
        qrcodeId: $scope.ui.data._id
        tagid_list: tagid_list
      .then (res) ->
        $scope.tagsPopover2.isOpen = false
        growl.success "打標籤成功！"
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.tagsPopoverDisabled = false


  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  # 菜單下載數據
  $scope.downloadMenu = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/menu/export_follower_list?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        version: $scope.ui.data.version
        key_id: $scope.ui.data.key_id
        start_created_at: $scope.ui.data.startDate
        end_created_at: $scope.ui.data.endDate
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  # 二維碼下載數據
  $scope.downloadQrcode = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/follower/export_list?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        qrcodeId: $scope.ui.data._id
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  $scope.cancel = ->
    $uibModalInstance.dismiss 'Cancel'
